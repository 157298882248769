<template>
  <v-row v-if="dataEvents" class="fill-height">
    <v-col cols="12">
      <v-sheet height="500">
        <v-calendar
          ref="calendar"
          :events="dataEvents.data"
          :interval-count="dataEvents.intervalCount"
          :first-interval="dataEvents.firstInterval"
          :interval-format="intervalFormat"
          :event-color="item => sortColor(item)"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          :locale="g_language"
          :short-weekdays="false"
          interval-height="36"
          start="2020-07-27"
          min-weeks="2"
          type="week"
          interval-minutes="15"
          event-overlap-mode="column"
          @click:event="goToDetail"
        >
          <template #day-label-header="{ day }">
            <div v-if="day" />
          </template>
          <template #event="{ eventParsed }">
            <div class="v-event-draggable">
              {{ eventParsed.input.name }}
            </div>
            <div v-if="isAdmin" class="v-event-draggable d-flex flex-column">
              <span
                v-for="teacher in eventParsed.input.teachers"
                :key="teacher.id"
              >
                - {{ teacher.name }}
              </span>
            </div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
    <v-overlay :value="loadingTable" :z-index="6">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <dialog-report
      :dialog="dialogReport"
      :subjectsName="subjectsName"
      :time="time"
      :className="className"
      :dataTable="recapAbsentStudent"
      @close="dialogReport = false"
    />
  </v-row>
</template>

<script>
import moment from "moment/moment";
import { getLessonSchedule } from "@/api/admin/academic/schedule";
import { mapGetters } from "vuex";
import { myRecapPerSubjects } from "@/api/admin/absent/recapAbsent";

export default {
  props: {
    idClass: Number,
    idGrade: Number,
    isInitData: Boolean,
    idSchoolYear: Number,
    idMonth: Object,
    itemTeacher: Array
  },
  components: {
    DialogReport: () => import("./DialogReport")
  },
  created() {
    moment.locale(this.g_language);
  },
  computed: {
    ...mapGetters(["g_language", "g_user"]),
    initData: {
      set(newValue) {
        this.resetInitData(newValue);
      },
      get() {
        return this.isInitData;
      }
    }
  },
  mounted() {
    if (this.isStudent) {
      this.getSchedule(this.g_user.student.class);
    }
  },
  watch: {
    isInitData(value) {
      if (value) {
        this.getSchedule(this.idClass);
      }
    },
    idSchoolYear() {
      if (this.idClass) this.getSchedule(this.idClass);
    },
    idClass(newValue) {
      this.getSchedule(newValue);
    },
    itemTeacher(newValue) {
      this.getSchedule(this.idClass, newValue);
    }
  },
  data() {
    return {
      loadingTable: false,
      dialogReport: false,
      dataEvents: null,
      isAdmin: this.$store.getters.g_role_type === "ADMIN",
      isTeacher: this.$store.getters.g_role_type === "TEACHER",
      isStudent: this.$store.getters.g_role_type === "STUDENT",
      className: "",
      time: "",
      subjectsName: "",
      recapAbsentStudent: []
    };
  },
  methods: {
    dateInMonth(date, month, year) {
      let days = new Date(year, month, 0).getDate();
      let dates = [new Date(month + `/${date}/` + year).getDay()];
      for (let i = dates[0] + 7; i < days; i += 7) {
        dates.push(i);
      }
      return dates;
    },
    epochDate: epoch => moment.unix(moment(epoch).unix()),
    async goToDetail(item) {
      const { event } = item;
      if (this.isStudent) {
        try {
          const startTime = this.epochDate(event.start).format("HH:mm");
          const endTime = this.epochDate(event.end).format("HH:mm");
          this.className = event.class_name;
          this.time = startTime + " - " + endTime;
          this.subjectsName = event.name;
          const response = await myRecapPerSubjects({
            lesson_schedule: parseInt(event.id)
          });
          const { data } = response;
          if (data.code) {
            this.recapAbsentStudent = data.data;
            this.dialogReport = true;
          } else this.snackBar(true, this.$i18n.t("app.there_is_an_error"));
        } catch (err) {
          console.error("goToDetail(item)\n", err);
          this.snackBar(true, this.$i18n.t("app.there_is_an_error"));
        }
      } else {
        this.$router.push({
          name: "DetailRecapAbsencePerSubject",
          query: {
            grade: this.idGrade,
            class: this.idClass
          },
          params: {
            id: event.id
          }
        });
      }
    },
    sortColor(item) {
      switch (item.flag) {
        case 0:
          return "grey darken-3";
        case 1:
          return "primary";
        case 2:
          return "orange";
        default:
          return "primary";
      }
    },
    intervalFormat: interval => interval.time,
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
      this.loadingSave = false;
    },
    getSchedule(idClass, idTeacher) {
      if (idClass) {
        this.loadingTable = true;
        this.$Progress.start();
        getLessonSchedule({
          class: idClass,
          school_year: this.idSchoolYear,
          teachers: idTeacher
        })
          .then(res => {
            if (res.data.code) {
              let data = JSON.parse(JSON.stringify(res.data.data));
              if (this.isTeacher) {
                const isTeach = data.data.filter(
                  item => item.is_i_teach.toUpperCase() === "TRUE"
                );
                data.data = isTeach;
              }
              this.dataEvents = data;
              this.dataEvents.data = data.data.filter(item => item.flag === 1);
              this.dataEvents.data.map(d => {
                d.startTime = d.start;
                d.endTime = d.end;
                d.start = moment(d.start).valueOf();
                d.end = moment(d.end).valueOf();
              });
              this.dataEvents.firstInterval *= 4.5;
              this.dataEvents.intervalCount *= 4.5;
            } else {
              this.snackBar(true, res.data.message);
            }
          })
          .catch(err => {
            this.snackBar(true, err);
            console.error("getSchedule(idClass)\n", err);
          })
          .finally(() => {
            this.loadingTable = false;
            this.initData = false;
            this.$Progress.finish();
          });
      }
    },
    resetInitData(value) {
      this.$emit("changeInitData", value);
    }
  }
};
</script>
<style lang="scss" scoped>
.v-calendar {
  user-select: none;
  -webkit-user-select: none;
}
.v-event-draggable {
  padding-left: 6px;
}
</style>
